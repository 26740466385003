<template>
    <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="wechatRobot-container">
        <div class="title">微信提醒设置</div>
        
        <div class="common-padding">
            <el-table border :data="tableData" style="width: 100%;margin-top: 32px">
            <el-table-column :formatter="tableColumn" prop="groupName" label="群名称"></el-table-column>
            <el-table-column prop="groupType" label="群类型">
                <template slot-scope="scope">
                    <div v-if="scope.row.groupType == 0">对接</div>
                    <div v-else-if="scope.row.groupType == 1">客服</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <!-- <el-table-column :formatter="tableColumn" prop="coefficient" label="提醒类型"></el-table-column> -->
            <el-table-column prop="login" label="登录异常是否发送">
                <template slot-scope="scope">
                    <div v-if="scope.row.login == 0">否</div>
                    <div v-else-if="scope.row.login == 1">是</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column prop="scheduling" label="客服排班是否发送">
                <template slot-scope="scope">
                    <div v-if="scope.row.scheduling == 0">否</div>
                    <div v-else-if="scope.row.scheduling == 1">是</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column prop="isAnnouncement" label="公告是否发送">
                <template slot-scope="scope">
                    <div v-if="scope.row.isAnnouncement == 0">否</div>
                    <div v-else-if="scope.row.isAnnouncement == 1">是</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <el-table-column prop="isAssessment" label="提醒预警类型">
                <template slot-scope="scope">
                    <div v-if="scope.row.isAssessment == 0">超时预警</div>
                    <div v-else-if="scope.row.isAssessment == 1">子账号均值</div>
                    <div v-else>--</div>
                </template>
            </el-table-column>
            <!-- <el-table-column :formatter="tableColumn" prop="groupNum" label="群数量"></el-table-column> -->
            <el-table-column :formatter="tableColumn" prop="daily" label="日报发送时间"></el-table-column>
            <el-table-column :formatter="tableColumn" prop="assessmentTime" label="服务均值发送时间"></el-table-column>
            <el-table-column :formatter="tableColumn" prop="chanceTime" label="销售机会发送时间"></el-table-column>
            <el-table-column prop="actualDay" label="操作">
                <template slot-scope="scope">
                    <div class="table-btn">
                        <el-button style="margin: 0 12px" type="text" @click="() => { $router.push(`/wechat/wechat-robot/setting?gid=${scope.row.gid}`) }">设置</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        </div>
    </div>
</template>
<script>
import { wechatList } from "../../service/dataInfo.js"
import { tableColumn, Config } from "../../utils/index.js"
export default {
    data () {
        return {
            tableColumn,
            companyId: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司id
            loading: false,
            tableData: [],
        }
    },
    created () {
        this.wechatList(this.companyId)
    },
    methods: {
        async wechatList(companyId) { // 列表
            this.loading = true
            let { data } = await wechatList({ compayId: companyId })
            this.loading = false
            this.tableData = data
        }
    }
}
</script>
<style lang="less" scoped>

.wechatRobot-container {
    text-align: left;
    /deep/.el-table th {
        background: #f2f4f5 !important;
    }
    .title {
        box-sizing: border-box;
        padding: 12px 0 28px;
        height: 68px;
        color: #333;
        font-size: 28px;
        line-height: 28px;
        text-indent: 28px;
        position: relative;
        &::after {
            content: ' ';
            display: block;
            width: 120%;
            position: absolute;
            bottom: 0;
            left: -10%;
            height: 1px;
            background: #ccc;
        }
    }
}
</style>